// Reset all styles
@import "_normalize"
@import "_fonts"

body
    width 100vw
    height 100vh
    background-color #0b29cc
    color white
    font-family 'HelveticaNeueCyr-Medium'
    display flex
    flex-direction column
    flex-wrap wrap
    justify-content flex-end
    align-items center
    align-content center

.logo
    background-image url("/img/logo_text.png")
    width 24.2vw
    height 6.9vw
    background-size contain
    background-position center
    background-repeat no-repeat
    margin-bottom 6.8vw
    @media screen and (min-aspect-ratio: 16/9)
        width 43.2vmin
        height 12.3vmin
        margin-bottom 12vmin
    @media screen and (max-width: 769px)
        width 206px
        height 60px
        margin-bottom 50px

.number
    font-size 5.74vw
    margin-bottom 4.2vw
    color white
    text-decoration none
    @media screen and (min-aspect-ratio: 16/9)
        font-size 10.24vmin
        margin-bottom 7.4vmin
    @media screen and (max-width: 769px)
        font-size 33px
        margin-bottom 50px

.button
    width 25vw
    height 6.2vw
    border-radius .4vw
    font-size 1.3vw
    margin-bottom 5.2vw
    background-color white
    display flex
    flex-direction column
    flex-wrap wrap
    justify-content center
    align-items center
    align-content center
    color #0014cb
    text-decoration none
    box-shadow 0 0 3vw rgba(black, .7)
    transition box-shadow .15s ease-in-out
    &:hover
        box-shadow 0 0 3vw rgba(black, 1)
    @media screen and (min-aspect-ratio: 16/9)
        width 44.6vmin
        height 11vmin
        border-radius .8vmin
        font-size 2.3vmin
        margin-bottom 9.2vmin
        box-shadow 0 0 7vmin rgba(0, 0, 0, .7)
        &:hover
            box-shadow 0 0 7vmin rgba(black, 1)
    @media screen and (max-width: 769px)
        width 192px
        height 50px
        font-size 10px
        border-radius 3px
        margin-bottom 120px
        box-shadow 0 0 20px rgba(0, 0, 0, .7)
        &:hover
            box-shadow 0 0 20px rgba(black, 1)
.mail
    font-size 1.35vw
    margin-bottom 1vw
    border-bottom .1vw solid #fff
    color white
    text-decoration none
    transition color .15s ease-in-out
    &:hover
        color rgba(white, .7)
    @media screen and (min-aspect-ratio: 16/9)
        font-size 2.4vmin
        margin-bottom 1.8vmin
        border-bottom .2vmin solid #fff
    @media screen and (max-width: 769px)
        font-size 13px
        margin-bottom 15px
        border-bottom 2px solid white




